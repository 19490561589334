<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 256.000000 256.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,256.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M450 2528 c-83 -24 -125 -83 -136 -192 l-7 -66 -36 0 c-47 -1 -112
-31 -139 -65 -34 -43 -43 -91 -40 -205 3 -105 3 -105 28 -105 25 0 25 0 30
120 4 82 10 127 20 143 19 28 69 52 109 52 29 0 31 -2 31 -39 0 -22 4 -51 10
-65 l10 -26 170 0 170 0 10 26 c6 14 10 43 10 65 l0 39 320 0 320 0 0 -39 c0
-22 4 -51 10 -65 l10 -26 170 0 170 0 10 26 c6 14 10 43 10 65 0 38 1 39 35
39 20 0 49 -7 65 -15 63 -32 60 -5 60 -620 l0 -561 -207 -199 c-209 -203 -214
-209 -257 -327 l-18 -48 -241 -2 -242 -3 0 -25 0 -25 231 -3 231 -2 -23 -66
c-27 -73 -30 -102 -11 -110 7 -2 93 25 192 61 l180 66 83 82 82 82 0 -169 0
-168 -33 -33 c-26 -26 -44 -34 -88 -40 -29 -3 -382 -5 -784 -3 -784 3 -757 1
-793 55 -16 25 -17 86 -22 813 l-5 785 -25 0 -25 0 0 -796 0 -795 25 -37 c13
-20 43 -46 65 -59 l40 -23 748 -3 c483 -2 768 1 804 8 66 12 113 45 139 97 16
32 19 68 22 238 l3 200 249 250 c136 138 255 264 264 282 22 44 20 108 -5 160
-25 51 -59 78 -122 94 -81 22 -114 5 -259 -139 l-127 -125 -3 524 c-3 575 -1
550 -65 611 -35 33 -73 48 -122 48 l-39 0 -7 68 c-8 76 -21 105 -62 144 -35
33 -73 48 -123 48 -50 0 -88 -15 -123 -48 -41 -39 -54 -68 -62 -144 l-7 -68
-318 0 -317 0 -7 66 c-11 111 -53 168 -141 193 -46 13 -48 13 -95 -1z m97 -63
c12 -3 34 -20 48 -36 24 -29 25 -36 25 -160 l0 -129 -120 0 -120 0 0 129 c0
125 1 131 26 160 25 30 75 52 104 46 8 -2 25 -6 37 -10z m1033 -10 c54 -28 60
-47 60 -187 l0 -128 -120 0 -120 0 0 126 c0 69 5 134 11 145 27 51 112 73 169
44z m755 -1169 c65 -27 96 -120 59 -176 -26 -38 -87 -100 -99 -100 -6 0 -48
38 -95 85 l-84 85 44 46 c69 71 112 86 175 60z m-470 -496 l-280 -280 -35 35
-35 35 280 280 280 280 35 -35 35 -35 -280 -280z m360 200 l29 -31 -279 -279
-280 -280 -35 35 -35 35 275 275 c151 151 280 275 286 275 5 0 23 -14 39 -30z
m-665 -542 c38 -39 67 -75 64 -79 -2 -4 -25 -14 -51 -23 l-47 -16 -38 37 c-21
20 -38 44 -38 52 0 18 31 101 37 101 3 0 36 -32 73 -72z m-100 -138 c0 -9 -50
-23 -56 -17 -3 2 0 17 6 32 10 27 11 27 30 10 11 -10 20 -21 20 -25z"
      />
      <path
        d="M234 2056 c-11 -29 0 -51 26 -51 21 0 25 5 25 30 0 24 -5 31 -23 33
-13 2 -24 -3 -28 -12z"
      />
      <path
        d="M235 1948 c-3 -7 -4 -124 -3 -258 l3 -245 25 0 25 0 0 255 0 255 -23
3 c-12 2 -24 -3 -27 -10z"
      />
      <path
        d="M390 1791 c-15 -29 -13 -323 3 -344 11 -16 32 -17 192 -15 l180 3 0
50 c0 47 -2 50 -25 50 -18 0 -26 -6 -28 -22 -3 -23 -6 -23 -133 -23 l-129 0 0
130 0 130 108 0 c133 0 152 5 152 36 l0 24 -155 0 c-142 0 -155 -1 -165 -19z"
      />
      <path
        d="M725 1704 c-16 -14 -45 -37 -63 -51 l-33 -26 -23 22 c-29 27 -60 24
-64 -5 -2 -14 10 -33 38 -57 22 -21 44 -37 49 -37 11 0 171 139 171 149 0 11
-23 31 -34 31 -6 -1 -24 -12 -41 -26z"
      />
      <path
        d="M903 1704 c-14 -38 21 -44 241 -44 116 0 221 3 234 6 15 4 22 14 22
30 l0 24 -245 0 c-219 0 -246 -2 -252 -16z"
      />
      <path
        d="M904 1565 c-4 -8 -4 -22 0 -30 5 -13 51 -15 363 -13 l358 3 0 25 0
25 -358 3 c-312 2 -358 0 -363 -13z"
      />
      <path
        d="M385 1207 c-9 -46 -2 -324 8 -334 14 -13 332 -16 356 -3 26 13 29 81
6 100 -19 16 -45 -3 -45 -31 0 -18 -9 -19 -130 -19 l-130 0 0 130 0 130 108 0
c133 0 152 5 152 36 l0 24 -160 0 -159 0 -6 -33z"
      />
      <path
        d="M695 1113 l-66 -56 -28 27 c-30 29 -61 27 -61 -3 0 -20 70 -91 89
-91 19 0 171 130 171 146 0 7 -9 18 -19 24 -16 8 -30 1 -86 -47z"
      />
      <path
        d="M904 1145 c-4 -8 -4 -22 0 -30 5 -13 39 -15 248 -13 l243 3 0 25 0
25 -243 3 c-209 2 -243 0 -248 -13z"
      />
      <path
        d="M903 994 c-15 -41 14 -44 368 -44 290 0 340 2 353 15 8 9 13 22 10
30 -5 13 -58 15 -365 15 -325 0 -360 -2 -366 -16z"
      />
      <path
        d="M386 648 c-12 -45 -7 -323 7 -341 11 -16 32 -17 192 -15 l180 3 0
185 0 185 -186 3 -187 2 -6 -22z m324 -168 l0 -130 -130 0 -130 0 0 130 0 130
130 0 130 0 0 -130z"
      />
      <path
        d="M903 564 c-14 -38 21 -44 241 -44 116 0 221 3 234 6 15 4 22 14 22
30 l0 24 -245 0 c-219 0 -246 -2 -252 -16z"
      />
    </g>
  </svg>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
